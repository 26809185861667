import React from "react";
import { graphql } from "gatsby";
import BlogLayout from "../components/v2/blog-layout/BlogLayout";
import BlogHeader from "../components/v2/blog-header/BlogHeader";
import { BlogList } from "../models/blog-list.model";
import styles from "../styles/v2/tag.module.scss";
import { GqlListResponse } from "../models/gql-list-response.model";
import cx from "classnames";
import BlogGridView from "../components/commons/blog-grid-view/BlogGridView";
import { GhostTag } from "../models/ghost/ghost-tag.model";
import SEO from "../components/commons/seo";
import { getTagLinkFromSlug } from "../utils/link.helper";

const TagTemplate = (props: any) => {
  const blogsRawData: GqlListResponse<BlogList> = props.data.blogs;
  const blogs = blogsRawData.edges.map(b => b.node);

  const tag: GhostTag = props.data.tag;

  return (
    <BlogLayout>
      <SEO
        title={tag.meta_title || tag.name}
        description={tag.meta_description}
        pageUrl={getTagLinkFromSlug(tag.slug)}
        keywords={[]}
        imageUrl={tag.feature_image}
        useSiteUrlForImage={false}
      />
      <BlogHeader className={styles.heading}>
        <h1 className="blog-d3">{tag.name}</h1>
      </BlogHeader>
      <main className={cx("column", styles.mainContent)}>
        <section className="column">
          <BlogGridView blogs={blogs} />
        </section>
      </main>
    </BlogLayout>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    blogs: allGhostPost(
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          slug
          id
          uuid
          title
          comment_id
          feature_image
          featured
          visibility
          published_at
          reading_time
          tags {
            id
            slug
            name
          }
        }
      }
    }

    tag: ghostTag(slug: { eq: $slug }) {
      name
      slug
      meta_title
      meta_description
      feature_image
    }
  }
`;

export default TagTemplate;
